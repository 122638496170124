import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import SEO from "../components/SEO"

export default ({ data }) => {
  const {
    file: {
      childImageSharp: { fluid: hero },
    },
  } = data
  return (
    <Layout>
      <SEO title="Page not found" description="This page seems not to exist" />
      <Hero
        height="40vh"
        title="Page Not Found"
        desc="Looks like you're lost"
        fluid={hero}
      />
      <div className="accent">
        <div className="container white center-align">
          <h3>
            Oops, it looks like you're as lost as a Cornerback trying to cover
            me.
          </h3>
          <Link to="/" className="btn-large hoverable">
            Return Home
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "aux1.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
